import React from 'react';

export default function ServicesSectionFeatures8() {
    return (
        <React.Fragment>
            <>
                <section className="relative py-20 2xl:py-40 bg-gray-800 overflow-hidden">
  <img className="hidden lg:block absolute top-0 left-0 -ml-96 -mt-40" src="zospace-assets/lines/circle.svg" alt="" />
  <img className="hidden lg:block absolute bottom-0 right-0 -mr-32 -mb-72" src="zospace-assets/lines/circle.svg" alt="" />
  <div className="relative container px-4 mx-auto">
    <div>
      <div className="flex flex-wrap -mx-10">
        <div className="relative w-full lg:w-1/2 px-10 mb-10 lg:mb-20">
          <img className="lg:hidden absolute top-0 right-0 -mt-16" src="zospace-assets/images/stars.svg" alt="" />
          <div className="px-16 pt-16 pb-24 bg-gray-600 rounded-lg">
            <span className="flex mb-10 justify-center items-center w-20 h-20 bg-blue-500 rounded-lg">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" aria-hidden="true" className="w-8 h-8 text-white">
                <path strokeLinecap="round" strokeLinejoin="round" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
              </svg>
            </span>
            <h3 className="mt-12 mb-8 text-4xl font-bold text-white">Community Management</h3>
            <p className="text-lg text-gray-200">A wealth of experience in community management, our company specializes in fostering engagement and growth within Twitch streamer communities, organizations, and gaming communities alike.</p>
          </div>
        </div>
        <div className="relative w-full lg:w-1/2 px-10 mb-10 lg:mb-20">
          <img className="hidden lg:block absolute top-0 right-0 -mt-16" src="zospace-assets/images/stars.svg" alt="" />
          <div className="px-16 pt-16 pb-24 bg-gray-600 rounded-lg">
            <span className="flex mb-10 justify-center items-center w-20 h-20 bg-green-500 rounded-lg">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" aria-hidden="true" className="w-8 h-8 text-white">
                <path strokeLinecap="round" strokeLinejoin="round" d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
              </svg>
            </span>
            <h3 className="mt-12 mb-8 text-4xl font-bold text-white">Software Development</h3>
            <p className="text-lg text-gray-200">Our company is dedicated to crafting visually stunning and highly functional software solutions that seamlessly adapt to various platforms. Including Websites.
            </p>
          </div>
        </div>
        <div className="w-full lg:w-1/2 px-10 mb-10 lg:mb-20">
          <div className="px-16 pt-16 pb-24 bg-gray-600 rounded-lg">
            <span className="flex mb-10 justify-center items-center w-20 h-20 bg-violet-500 rounded-lg">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" aria-hidden="true" className="w-8 h-8 text-white">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
              </svg>
            </span>
            <h3 className="mt-12 mb-8 text-4xl font-bold text-white">Technology Support</h3>
            <p className="text-lg text-gray-200">Elele Tech is a technology services sub-company that offers a wide range of solutions to help individuals and businesses optimize their technology use.</p>
          </div>
        </div>
        <div className="w-full lg:w-1/2 px-10">
          <div className="px-16 pt-16 pb-24 bg-gray-600 rounded-lg">
            <span className="flex mb-10 justify-center items-center w-20 h-20 bg-pink-500 rounded-lg">
              <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="w-8 h-8 text-white" viewBox="0 0 16 16">
                <path d="M4.5 5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM3 4.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm2 7a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm-2.5.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                <path d="M2 2a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h1v2H2a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-1a2 2 0 0 0-2-2h-1V7h1a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2zm13 2v1a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm0 7v1a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm-3-4v2H4V7h8z" />
              </svg>
            </span>
            <h3 className="mt-12 mb-8 text-4xl font-bold text-white">Server Provisioning</h3>
            <p className="text-lg text-gray-200">From setting up game servers to deploying bots and beyond, our team ensures optimal performance and reliability for your digital ecosystem.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

