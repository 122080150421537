import React from 'react';

export default function StorySectionContent8() {
    return (
        <React.Fragment>
            <>
                <section className="py-20 2xl:py-40 bg-gray-800 overflow-hidden"><div className="container px-4 mx-auto">
    <div className="max-w-2xl mx-auto text-center">
      <span className="text-xl lg:text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-br from-yellow-200 to-orange-600">OUR STORY</span>
      <h2 className="mt-7 mb-14 text-6xl 2xl:text-7xl text-white font-bold font-heading">Learn More About Elele.</h2>
      <div className="max-w-xl mx-auto">
        <p className="mb-6 text-left text-xl text-gray-200">Founded in 2016, Elele Team began its journey as a dynamic gaming organization. Our early endeavors focused on fostering vibrant communities within popular gaming platforms such as Arma 3, Minecraft, and Garry's Mod. As our interests and expertise expanded, the scope of our operations evolved, leading to the establishment of a dedicated technology development enterprise, Elele Team LTD, headquartered in the United Kingdom.</p>
        <p className="mb-6 text-left text-xl text-gray-200">This initial venture into the technological sphere was pivotal but eventually gave way to a more refined and focused entity, Elele Tech Services. Our evolution continued, culminating in a significant transformation in 2024 when we rebranded as Elele Group. This change marked a new chapter in our history, aligning our operations under a unified corporate umbrella.</p>
        <p className="mb-6 text-left text-xl text-gray-200">With the rebranding, Elele Group proudly launched two major subsidiaries: Noir eSports and Elele Tech. Noir eSports emerged as a formidable presence in the competitive gaming arena, partly owned by Not Suitable Group LTD. This strategic partnership has propelled Noir eSports to new heights, setting the stage for future successes across our diverse ventures.</p>
        <p className="mb-6 text-left text-xl text-gray-200">Together, Elele Group and its subsidiaries are committed to innovation and excellence in both the technology and eSports industries, continually pushing the boundaries of what is possible.
        </p>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

