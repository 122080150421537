import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import IndexSectionHeaders8 from '../components/headers/IndexSectionHeaders8';
import IndexSectionLogoClouds3 from '../components/logo-clouds/IndexSectionLogoClouds3';
import IndexSectionFeatures4 from '../components/features/IndexSectionFeatures4';
import IndexSectionCallToAction5 from '../components/call-to-action/IndexSectionCallToAction5';
import IndexSectionFooters7 from '../components/footers/IndexSectionFooters7';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <IndexSectionHeaders8 />
      <IndexSectionLogoClouds3 />
      <IndexSectionFeatures4 />
      <IndexSectionCallToAction5 />
      <IndexSectionFooters7 />
    </React.Fragment>
  );
}

