import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import AboutSectionNavigations1 from '../components/navigations/AboutSectionNavigations1';
import AboutSectionTeam7 from '../components/team/AboutSectionTeam7';
import AboutSectionNewsletter4 from '../components/newsletter/AboutSectionNewsletter4';
import AboutSectionFooters8 from '../components/footers/AboutSectionFooters8';

const meta = {
  title: 'Elele Group LTD',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function About() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <AboutSectionNavigations1 />
      <AboutSectionTeam7 />
      <AboutSectionNewsletter4 />
      <AboutSectionFooters8 />
    </React.Fragment>
  );
}

