import React, { useState } from 'react';

export default function ServicesSectionFaqs12() {
    // State to manage which FAQ is open. Null means all are closed.
    const [openFaqIndex, setOpenFaqIndex] = useState(null);

    // Function to handle FAQ item toggle
    const toggleFaq = index => {
        // If the index is the same as the open one, close it, otherwise open the new one
        setOpenFaqIndex(openFaqIndex === index ? null : index);
    };

    return (
        <React.Fragment>
            <section className="relative pb-20 2xl:py-40 bg-gray-800 overflow-hidden">
                <img className="hidden lg:block absolute z-10 top-0 bottom-0 h-full w-2/6 object-cover" src="https://images.unsplash.com/photo-1618176729090-253077a8f948?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1200&q=80" alt="" />
                <img className="hidden lg:block absolute bottom-0 left-0 h-128 -mb-64 ml-64" src="zospace-assets/lines/circle.svg" alt="" />
                <div className="relative w-full lg:w-4/6 ml-auto pl-4 lg:pl-24 pr-4 pt-20">
                    <div className="mb-20">
                        <span className="text-lg font-bold text-blue-400">Hey! Have any questions?</span>
                        <h2 className="mt-8 text-7xl text-white font-bold font-heading">FAQ's</h2>
                    </div>
                    <div className="max-w-4xl">
                        <ul>
                            {[
                                {
                                    question: "I have a question about a service, or want to learn more?",
                                    answer: "There are plenty of ways to get in touch with us if you are interested or have a question about our services.",
                                    points: ["Email", "Discord", "Mobile", "Post"]
                                },
                                {
                                    question: "Can I pay by card?",
                                    answer: "Yes, we accept all major credit cards."
                                },
                                {
                                    question: "How does the process work?",
                                    answer: "You start by submitting your request on our website."
                                },
                                {
                                    question: "Do you offer free consultation?",
                                    answer: "Yes, the initial consultation is free of charge."
                                }
                            ].map((faq, index) => (
                                <li key={index} className="mb-4 px-4 lg:px-12 py-8 bg-gray-700 rounded-2xl">
                                    <button className="flex w-full text-left" onClick={() => toggleFaq(index)}>
                                        <div className="w-auto mr-8">
                                            <span className="flex items-center justify-center w-12 h-12 text-lg font-bold bg-white rounded-full">{index + 1}</span>
                                        </div>
                                        <div className="w-full mt-3">
                                            <div className="flex items-center justify-between">
                                                <h3 className="text-xl font-bold text-white">{faq.question}</h3>
                                                <span className="ml-4 rotate-0 transition-transform duration-300" style={{ transform: openFaqIndex === index ? 'rotate(180deg)' : 'rotate(0)' }}>
                                                    <svg className="w-4 h-4" width={18} height={10} viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1 9L9 1l8 8" stroke="#1F40FF" strokeWidth="2" />
                                                    </svg>
                                                </span>
                                            </div>
                                            {openFaqIndex === index && (
                                                <div className="mt-6 border-l-2 border-gray-500 pl-10">
                                                    <p className="mb-5 text-xl text-gray-300">{faq.answer}</p>
                                                    {faq.points && (
                                                        <ul>
                                                            {faq.points.map((point, pIndex) => (
                                                                <li key={pIndex} className="mb-2 text-lg text-gray-300">
                                                                    <span className="inline-block mr-6 h-2 w-2 rounded-full bg-blue-500" />
                                                                    {point}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}