import React from 'react';
import {
  Routes,
  Route
} from 'react-router-dom';

import IndexPage from './pages/Index.js';
import AboutPage from './pages/About.js';
import ServicesPage from './pages/Services.js';
import StoryPage from './pages/Story.js';
import AppsPage from './pages/Apps.js';
import HttpCodesPage from './pages/Http-codes.js';
import ContactPage from './pages/Contact.js';

function App() {
  return (
    <Routes>
      <Route path="/" element={<IndexPage />} />
         
      <Route path="/about" element={<AboutPage />} />
         
      <Route path="/services" element={<ServicesPage />} />
         
      <Route path="/story" element={<StoryPage />} />
         
      <Route path="/apps" element={<AppsPage />} />
         
      <Route path="*" element={<HttpCodesPage />} />
         
      <Route path="/contact" element={<ContactPage />} />
    </Routes>
  );
}

export default App;
