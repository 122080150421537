import React from 'react';

export default function AboutSectionTeam7() {
  return (
    <React.Fragment>
      <>
        <section className="relative py-20 2xl:py-40 bg-gray-800 overflow-hidden">
          <img className="absolute top-0 left-0 -mt-80 -ml-96" src="zospace-assets/lines/circle.svg" alt="" />
          <img className="absolute bottom-0 right-0 h-full -mb-40 -mr-96" src="zospace-assets/lines/circle.svg" alt="" />
          <div className="relative container px-4 mx-auto">
            <h2 className="mb-24 text-center text-5xl font-bold font-heading text-white">Our Team</h2>
            <div className="flex flex-wrap justify-center -mx-5 mb-20">
              <div className="w-full lg:w-1/3 px-5 mb-10 lg:mb-0">
                <img className="w-full h-112 lg:h-80 object-cover object-top" src="https://static.shuffle.dev/uploads/files/8b/8b59cbd560eb07b4c4f26589eeb008d12c609948/aMUGSk.png" alt="" />
                <div className="py-10 px-16 bg-gray-700 rounded-b-lg text-center">
                  <h3 className="mb-2 text-3xl text-white font-bold font-heading">Hunter R</h3>
                  <p className="mb-10 text-white">Co-Founder &amp; Managing Director</p>
                  <div className="py-px mb-8 bg-gray-500" />
                  <p className="mb-14 text-lg text-gray-200">Co-Founder of Elele Team, Chief Technology Officer at Noir eSports and Managing Director for Elele Group UK/US.</p>
                  <div>
                    <a className="inline-block" href="https://twitter.com/huntarr_" target='_blank'>
                      <img className="h-12" src="zospace-assets/logos/twitter.svg" alt="Twitter/X" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-1/3 px-5 mb-10 lg:mb-0">
                <img className="w-full h-112 lg:h-80 object-cover object-center" src="https://static.shuffle.dev/uploads/files/8b/8b59cbd560eb07b4c4f26589eeb008d12c609948/35XV4c7t-400x400.jpg" alt="" />
                <div className="py-10 px-16 bg-gray-700 rounded-b-lg text-center">
                  <h3 className="mb-2 text-3xl text-white font-bold font-heading">Jack I</h3>
                  <p className="mb-10 text-white">Co Founder &amp; Executive Director</p>
                  <div className="py-px mb-8 bg-gray-500" />
                  <p className="mb-14 text-lg text-gray-200">Co-Founder of Elele Team, Co-Founder of Noir eSports and Full-Stack Developer. Jack is also Executive Director at Elele Group LTD.</p>
                  <div>
                    <a className="inline-block" href="https://twitter.com/Jackelele_" target='_blank'>
                      <img className="h-12" src="zospace-assets/logos/twitter.svg" alt="Twitter/X" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-1/3 px-5">
                <img className="w-full h-112 lg:h-80 object-cover object-center" src="https://images.unsplash.com/photo-1615803795804-06a0c2a0030e?q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=940&w=940" alt="" />
                <div className="py-10 px-16 bg-gray-700 rounded-b-lg text-center">
                  <h3 className="mb-2 text-3xl text-white font-bold font-heading">Matty B</h3>
                  <p className="mb-10 text-white">Co-Founder &amp; Developer</p>
                  <div className="py-px mb-8 bg-gray-500" />
                  <p className="mb-14 text-lg text-gray-200">Co-Founder of Elele Team and Software Developer. Matty works on Server Provisioning, Community Management and Software Development.</p>
                  <div>
                    <a className="inline-block" href="https://twitter.com/mattyelele" target='_blank'>
                      <img className="h-12" src="zospace-assets/logos/twitter.svg" alt="Twitter/X" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


      </>
    </React.Fragment>
  );
}

