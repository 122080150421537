import React from 'react';

export default function ServicesSectionLogoClouds11() {
    return (
        <React.Fragment>
            <>
                <section className="pb-24 pt-20 bg-gray-800 overflow-hidden">
  <div className="container px-4 mx-auto">
    <div className="flex flex-wrap items-center -mx-4">
      <div className="w-full lg:w-2/5 px-4 mb-20 lg:mb-0">
        <div className="lg:max-w-lg">
          <span className="text-lg text-blue-400 font-bold">WHAT WE USE</span>
          <h2 className="mt-8 text-6xl 2xl:text-7xl font-bold font-heading text-white">Behind Our Operations</h2>
        </div>
      </div>
      <div className="w-full lg:w-3/5 px-4">
        <div className="flex justify-center items-center">
          <div className="py-8 px-2 sm:px-6 mr-3 bg-gray-600 rounded-lg">
            <img className="h-16 mx-auto" src="https://static.shuffle.dev/uploads/files/8b/8b59cbd560eb07b4c4f26589eeb008d12c609948/636e0a6a49cf127bf92de1e2-icon-clyde-blurple-RGB.png" alt="" />
          </div>
          <div className="flex flex-col mr-3">
            <div className="inline-block ml-auto py-12 px-4 sm:px-8 mb-3 rounded-lg bg-gray-600">
              <img className="mx-auto h-24" src="https://static.shuffle.dev/uploads/files/8b/8b59cbd560eb07b4c4f26589eeb008d12c609948/android-apple-iiodgcy3xjyn80d1-removebg-preview.png" alt="" />
            </div>
            <div className="py-16 px-2 sm:px-12 bg-gray-600 rounded-lg">
              <img className="h-4 md:h-8 mx-auto" src="zospace-assets/logos/slack-box.svg" alt="" />
            </div>
          </div>
          <div className="-mb-32">
            <div className="py-8 px-2 sm:px-14 mb-3 bg-gray-600 rounded-lg">
              <img className="mx-auto h-24" src="https://static.shuffle.dev/uploads/files/8b/8b59cbd560eb07b4c4f26589eeb008d12c609948/EleleTechLogoText.png" alt="" />
            </div>
            <div className="inline-block py-14 px-2 sm:px-8 bg-gray-600 rounded-lg">
              <img className="h-6 mx-auto" src="https://static.shuffle.dev/uploads/files/8b/8b59cbd560eb07b4c4f26589eeb008d12c609948/tailwindcss-logotype-white-944c5d0ef628083bb316f9b3d643385c86bcdb3d.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

