import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import AppsSectionNavigation1 from "../components/navigations/AboutSectionNavigations1"
import AppsSectionApplications2 from '../components/applications/AppsSectionApplications2';
import AppsSectionFeatures3 from '../components/features/AppsSectionFeatures3';
import AppsSectionFooters5 from '../components/footers/AppsSectionFooters5';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Apps() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <AppsSectionNavigation1 />
      <AppsSectionApplications2 />
      <AppsSectionFeatures3 />
      <AppsSectionFooters5 />
    </React.Fragment>
  );
}

