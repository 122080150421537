import React, { useState } from 'react';


export default function AboutSectionNavigations1() {
    // State to manage the visibility of the mobile menu
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    // Function to toggle the menu state
    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };
  
  return (
    <React.Fragment>
      <>
        <section className="pb-20 bg-gray-800">
          <nav className="relative">
            <div className="flex py-8 px-4 lg:px-8 justify-between items-center">
              <a className="text-2xl text-white font-bold" href="/">ELELE GROUP</a>
              <div className="lg:hidden">
                <button className="p-2 navbar-burger" onClick={toggleMenu}>
                  <svg className="w-10 h-3" width={39} height={13} viewBox="0 0 39 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width={39} height={2} rx={1} fill="#C4C4C4" />
                    <rect x={19} y={11} width={20} height={2} rx={1} fill="#C4C4C4" />
                  </svg>
                </button>
              </div>
              <div className={`lg:block ${isMenuOpen ? 'block' : 'hidden'} absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2`}>
                <ul className="flex items-center text-white space-x-10">
                  <li><a className="text-white font-bold text-lg" href="/about">About Us</a></li>
                  <span><svg width={5} height={5} viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="2.5" cy="2.5" r="2.5" fill="#726B6B" /></svg></span>
                  <li><a className="text-white font-bold text-lg" href="/services">Services</a></li>
                  <span><svg width={5} height={5} viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="2.5" cy="2.5" r="2.5" fill="#726B6B" /></svg></span>
                  <li><a className="text-white font-bold text-lg" href="/story">Our Story</a></li>
                  <span><svg width={5} height={5} viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="2.5" cy="2.5" r="2.5" fill="#726B6B" /></svg></span>
                  <li><a className="text-white font-bold text-lg" href="/contact">Contact</a></li>
                </ul>
              </div>
            </div>
          </nav>
          <div className={`navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-sm z-50 ${isMenuOpen ? 'flex' : 'hidden'}`}>
            <div className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-80" onClick={toggleMenu} />
            <nav className="relative flex flex-col py-8 h-full w-full bg-white overflow-y-auto">
              <div className="flex items-center mb-16 pr-6">
                <a className="ml-10 mr-auto text-2xl text-gray-800 font-bold" href="/">ELELE GROUP</a>
              </div>
              <div>
                <ul>
                  <li className="mb-1 px-10"><a className="block pl-8 py-4 text-xl text-gray-800 hover:bg-blueGray-50 rounded-xl" href="/about">About Us</a></li>
                  <li className="mb-1 px-10"><a className="block pl-8 py-4 text-xl text-gray-800 hover:bg-blueGray-50 rounded-xl" href="/services">Services</a></li>
                  <li className="mb-1 px-10"><a className="block pl-8 py-4 text-xl text-gray-800 hover:bg-blueGray-50 rounded-xl" href="/story">Our Story</a></li>
                  <li className="mb-1 px-10"><a className="block pl-8 py-4 text-xl text-gray-800 hover:bg-blueGray-50 rounded-xl" href="/contact">Contact</a></li>
                </ul>
              </div>
              <div className="mt-auto px-10">
                <p className="mt-6 mb-4 text-lg text-center">
                  <span>2024 © Elele Group. All rights reserved.</span>
                </p>
              </div>
            </nav>
          </div>
        </section>


      </>
    </React.Fragment>
  );
}

