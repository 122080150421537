import React from 'react';

export default function ContactSectionNewsletter6() {
    return (
        <React.Fragment>
            <>
                <section className="py-20 2xl:py-40 bg-gray-800 overflow-hidden">
  <div className="relative container px-4 mx-auto">
    <img className="hidden lg:block absolute top-0 left-0 -mt-12 -ml-80" src="zospace-assets/lines/circle.svg" alt="" />
    <div className="relative max-w-5xl mx-auto">
      <div className="flex flex-wrap -m-4">
        <div className="w-full lg:w-1/2 p-4 mb-12 lg:mb-0">
          <a className="inline-block text-white text-xl font-bold" href="#">ELELE GROUP</a>
          <h2 className="mt-16 mb-14 text-6xl lg:text-7xl font-bold font-heading text-white">Join the newsletter!</h2>
          <p className="max-w-xs text-gray-200">Subscribe to our newsletter to learn more about Elele  Group, our projects, news and more.</p>
        </div>
        <div className="w-full lg:w-1/2 p-4">
          <div className="relative pt-24 px-6 lg:px-16 pb-20 bg-violet-600 rounded-3xl">
            <img className="absolute top-0 right-0 -mt-12 mr-12" src="zospace-assets/images/stars.svg" alt="" />
            <div>
              <span className="text-base lg:text-lg text-white font-bold">Join now!</span>
              <h3 className="mt-6 mb-12 text-5xl lg:text-5xl text-white font-bold font-heading">Subscribe now</h3>
              <div className="lg:max-w-md mb-4 flex items-center bg-white rounded-full">
                <span className="inline-block pl-10">
                  <svg width={37} height={37} viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="18.5" cy="18.5" r="9.5" fill="#1F40FF" fillOpacity="0.15" /><circle cx="18.5" cy="18.5" r="18.5" fill="#1F40FF" fillOpacity="0.06" /><circle cx="18.5" cy="18.5" r="2.5" fill="#282C36" /></svg>
                </span>
                <input className="pl-4 py-5 rounded-full placeholder-gray-900 font-bold focus:outline-none" type="email" placeholder="Drop your Email" />
              </div>
              <div className="flex items-center">
                <p className="text-blueGray-300">Please read our Privacy Policy.</p>
                <button className="ml-auto px-4 lg:px-10 py-5 text-white font-bold bg-gray-900 hover:bg-gray-800 rounded-full transition duration-200">Subscribe</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

