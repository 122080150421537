import React from 'react';

export default function IndexSectionFooters7() {
  return (
    <React.Fragment>
      <>
        <section className="py-20 2xl:py-40 bg-gray-800"><div className="container mx-auto px-4 mb-12 md:mb-20">
          <div className="max-w-4xl mx-auto text-center">
            <a className="inline-block mb-20 text-white text-3xl font-bold" href="/">ELELE GROUP</a>
            <ul className="mb-12 md:mb-20 flex flex-wrap items-center justify-center space-x-6 md:justify-between text-lg"><li className="mb-4 md:mb-0"><a className="font-bold text-white hover:text-gray-100" href="#">Home</a></li>
              <li className="mb-4 md:mb-0"><a className="font-bold text-white hover:text-gray-100" href="/about">About Us</a></li>
              <li className="mb-4 md:mb-0"><a className="font-bold text-white hover:text-gray-100" href="/services">Services</a></li>
              <li className="mb-4 md:mb-0"><a className="font-bold text-white hover:text-gray-100" href="/story">Our Story</a></li>
              <li className="mb-4 md:mb-0"><a className="font-bold text-white hover:text-gray-100" href="/contact">Contact</a></li>
            </ul>
          </div>
        </div>
          <p className="text-center text-lg text-white pt-12 px-4 border-t border-gray-500">
            <span>© 2024 Elele Group</span>
            <span className="text-gray-300"> All rights reserved.</span>
          </p>
        </section>


      </>
    </React.Fragment>
  );
}

