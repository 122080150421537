import React from 'react';

export default function AppsSectionApplications2() {
    return (
        <React.Fragment>
            <>
                <section className="relative py-20 2xl:py-40 bg-gray-800 overflow-hidden">
  <img className="hidden md:block absolute top-0 right-0 h-96 mt-24 object-contain" src="zospace-assets/images/phone-half.png" alt="" />
  <div className="container px-4 mx-auto">
    <img className="hidden lg:block absolute left-0 bottom-0 -ml-64" src="zospace-assets/lines/full-circle.svg" alt="" />
    <img className="hidden lg:block absolute left-0 bottom-0 w-96 h-96 -ml-64 -mb-24" src="zospace-assets/lines/circle.svg" alt="" />
    <div className="relative max-w-xl">
      <a className="inline-block text-2xl text-white font-bold" href="#">
        <img className="h-7" src="zospace-assets/logos/zospace-logo.svg" alt="" width="auto" />
      </a>
      <h2 className="my-12 text-5xl font-bold font-heading text-white">Nice widgets. Pinned conversation. No ads.</h2>
      <p className="mb-14 text-xl text-white">Get our free mobile application.</p>
      <a className="inline-block px-12 py-5 bg-blue-500 hover:bg-blue-600 rounded-full text-lg font-bold text-white transition duration-200" href="#">Downolad</a>
    </div>
    <img className="hidden lg:block absolute bottom-0 right-0 max-w-md 2xl:max-w-xl -mb-24 mr-24" src="zospace-assets/images/phone-turn.png" alt="" />
  </div>
</section>


            </>
        </React.Fragment>
    );
}

