import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import HttpCodesSectionNavigations6 from '../components/navigations/HttpCodesSectionNavigations6';
import HttpCodesSectionHttpCodes2 from '../components/http-codes/HttpCodesSectionHttpCodes2';
import HttpCodesSectionFooters5 from '../components/footers/HttpCodesSectionFooters5';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function HttpCodes() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <HttpCodesSectionNavigations6 />
      <HttpCodesSectionHttpCodes2 />
      <HttpCodesSectionFooters5 />
    </React.Fragment>
  );
}

