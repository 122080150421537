import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import StorySectionNavigations9 from '../components/navigations/StorySectionNavigations9';
import StorySectionContent8 from '../components/content/StorySectionContent8';
import StorySectionFooters7 from '../components/footers/StorySectionFooters7';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Story() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <StorySectionNavigations9 />
      <StorySectionContent8 />
      <StorySectionFooters7 />
    </React.Fragment>
  );
}

