import React from 'react';

export default function IndexSectionCallToAction5() {
    return (
        <React.Fragment>
            <>
                <section className="py-20 2xl:py-40 overflow-hidden"><div className="container px-4 mx-auto">
    <div className="max-w-3xl mx-auto text-center">
      <a className="inline-block mx-auto text-gray-900 text-xl font-bold" href="/">
        ELELE GROUP
      </a>
      <h2 className="my-8 text-6xl lg:text-7xl font-bold font-heading">Get In Touch.</h2>
      <p className="mb-14 text-lg text-gray-500">Contact Us to learn more.</p>
      <a className="inline-block py-4 px-12 text-white font-bold bg-blue-500 hover:bg-blue-600 rounded-full transition duration-200" href="/contact">Say hello!</a>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

