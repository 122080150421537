import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ServicesSectionNavigations6 from '../components/navigations/ServicesSectionNavigations6';
import ServicesSectionContent9 from '../components/content/ServicesSectionContent9';
import ServicesSectionFeatures8 from '../components/features/ServicesSectionFeatures8';
import ServicesSectionLogoClouds11 from '../components/logo-clouds/ServicesSectionLogoClouds11';
import ServicesSectionFaqs12 from '../components/faqs/ServicesSectionFaqs12';
import ServicesSectionFooters10 from '../components/footers/ServicesSectionFooters10';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Services() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <ServicesSectionNavigations6 />
      <ServicesSectionContent9 />
      <ServicesSectionFeatures8 />
      <ServicesSectionLogoClouds11 />
      <ServicesSectionFaqs12 />
      <ServicesSectionFooters10 />
    </React.Fragment>
  );
}

