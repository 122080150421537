import React from 'react';

export default function IndexSectionLogoClouds3() {
    return (
        <React.Fragment>
            <>
                <section className="py-20 bg-gray-800">
  <div className="container px-4 mx-auto">
    <h2 className="mb-12 text-center text-xl font-bold font-heading text-white">Our Companies</h2>
    <div className="flex flex-wrap -m-4 justify-center">
      <div className="w-full md:w-1/2 lg:w-1/5 p-4">
        <div className="flex w-full h-32 items-center justify-center border-2 border-gray-600 rounded-lg">
          <img className="h-24" src="https://static.shuffle.dev/uploads/files/8b/8b59cbd560eb07b4c4f26589eeb008d12c609948/Noir.png" alt="" />
        </div>
      </div>
      <div className="w-full md:w-1/2 lg:w-1/5 p-4">
        <div className="flex w-full h-32 items-center justify-center border-2 border-gray-600 rounded-lg">
          <img className="" src="https://static.shuffle.dev/uploads/files/8b/8b59cbd560eb07b4c4f26589eeb008d12c609948/EleleTechLogoText.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

